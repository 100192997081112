import React, { useState } from "react";
import { Task } from "./components";
import {
  getTasks as getTasksData,
  getTasksVariables,
} from "../../lib/graphql/query/Task/__generated__/getTasks";
import {
  updateTask as updateTasksData,
  updateTaskVariables,
} from "../../lib/graphql/query/Task/__generated__/updateTask";
import { useQuery, useMutation } from "@apollo/client";
import { GET_TASKS, UPDATE_TASK } from "../../lib/graphql/query/Task";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";

import { toast } from "react-toastify";

import { OnboardingScreen } from "../../components/Onboarding";

import ufo from "../../assets/blank_state/ufo.png";
import clipboard from "../../assets/blank_state/clipboard.png";

import { CreateTaskModalImp } from "../../components/CreateTask";

import "./styles.css";

document.title = "Checklist | Qubits";

const months: any = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const Checklist = ({ viewer, setViewer }: any) => {
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  let history = useNavigate();
  let location = useLocation();
  const today = dayjs();

  const { data, loading, error } = useQuery<getTasksData, getTasksVariables>(
    GET_TASKS,
    {
      variables: { userId: viewer.id },
      notifyOnNetworkStatusChange: true,
      onError: () => {
        toast("😐 Oh... Reload the page and try again");
      },
    }
  );

  const [updateTask] = useMutation<updateTasksData, updateTaskVariables>(
    UPDATE_TASK
  );

  const handleUpdateTask = async (taskId: string, input: any) => {
    await updateTask({
      variables: {
        userId: viewer.id,
        taskId: taskId,
        input: input,
      },
      onCompleted: (data) => {
        if (data.updateTask === null) {
          toast("😐 Oh... Reload the page and try again");
        }
      },
      onError: () => {
        toast("😐 Oh... Reload the page and try again");
      },
    });
  };

  const list = data ? data.getTasks : null;
  if (data) {
  }
  if (loading) {
  }
  if (error) {
    toast("😐 Oh... Reload the page and try again");
  }

  const Tasks = () => {
    const [date, setDate] = useState(
      dayjs(location.pathname.split("/")[2])
        ? dayjs(location.pathname.split("/")[2])
        : today
    );

    return (
      <div>
        {loading ? (
          <div>
            <div
              className="lds-ring loading-width"
              style={{
                display: "flex",
                justifyContent: "center",
                opacity: 0.5,
              }}
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : null}
        {list?.length !== 0 &&
        list?.filter(
          (item) =>
            item?.status === "active" ||
            item?.entries.includes(date.format("YYYY-MM-DD") + "T00:00:00.000Z")
        ).length === 0 ? (
          <div className="checklist-blank">
            <img src={clipboard} width={150} height={150}></img>

            <div className="checklist-blank-text">
              Feeling task-less? Fuel your growth by creating new ones!
            </div>

            <button
              onClick={() => setSubmitModalOpen(true)}
              className="add-task-button checklist-blank-button"
            >
              + Create task
            </button>
          </div>
        ) : null}

        {data?.getTasks && list && list.length === 0 ? (
          <div className="checklist-blank">
            <img src={ufo} width={150} height={150}></img>
            <div className="checklist-blank-text">
              Welcome aboard! Let's start your journey of growth by creating
              first regular task.
            </div>

            <button
              onClick={() => setSubmitModalOpen(true)}
              className="add-task-button checklist-blank-button"
            >
              + Create task
            </button>
          </div>
        ) : (
          <div style={{ marginBottom: 5 }}>
            <div className="date-picker">
              <div
                className="date-picker__before"
                onClick={() => {
                  history(
                    `/checklist/${date.subtract(1, "day").format("YYYY-MM-DD")}`
                  );
                  setDate(date.subtract(1, "day"));
                }}
              ></div>
              <div className="date-picker__main">
                <span className="date-picker__month">
                  {months[date.month()] +
                    " " +
                    date.date() +
                    ", " +
                    date.year()}
                </span>
                {/*<div className="calendar-icon" style={{ height: 40 }}>
            <img src={cal} height="20" style={{ marginTop: 9 }} />
  </div>*/}
              </div>
              <div
                className="date-picker__after"
                onClick={() => {
                  history(
                    `/checklist/${date.add(1, "day").format("YYYY-MM-DD")}`
                  );
                  setDate(date.add(1, "day"));
                }}
              ></div>
            </div>
          </div>
        )}

        <div className="tasks">
          {list
            ? list
                .filter(
                  (item) =>
                    item?.status === "active" ||
                    item?.entries.includes(
                      date.format("YYYY-MM-DD") + "T00:00:00.000Z"
                    )
                )
                .map((d) => (
                  <div>
                    <Task
                      key={d!.id}
                      id={d!.id}
                      title={d!.title}
                      color={d!.color}
                      entries={d!.entries}
                      status={d!.status}
                      date={date}
                      updateTask={handleUpdateTask}
                      viewer={viewer}
                    />
                  </div>
                ))
            : null}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {viewer.seenIntro === false ? (
        <OnboardingScreen viewer={viewer} setViewer={setViewer} />
      ) : null}
      <CreateTaskModalImp
        isOpen={submitModalOpen}
        viewer={viewer}
        setOpen={setSubmitModalOpen}
      />
      <div
        className="content"
        style={{
          fontSize: 24,
          marginBottom: 20,
        }}
      >
        <div className="total-panel" style={{ fontSize: 24, marginBottom: 20 }}>
          <span style={{}}>Checklist </span>
          <div className="add-task-button">
            <button onClick={() => setSubmitModalOpen(true)}>
              + Create task
            </button>
          </div>
        </div>
        <Tasks />
      </div>
    </React.Fragment>
  );
};
