import React, { useState } from "react";

import more from "../../assets/moree.svg";
import close from "../../assets/Close.svg";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { useMutation } from "@apollo/client";

import {
  updateTask as updateTasksData,
  updateTaskVariables,
} from "../../../../lib/graphql/query/Task/__generated__/updateTask";
import {
  deleteTask as deleteTasksData,
  deleteTaskVariables,
} from "../../../../lib/graphql/query/Task/__generated__/deleteTask";
import {
  GET_TASKS,
  UPDATE_TASK,
  CREATE_TASK,
  DELETE_TASK,
} from "../../../../lib/graphql/query/Task";

import { toast } from "react-toastify";
import { DeleteTaskModal } from "../../../../components/DeleteTaskModal";

import { UpdateTaskModal } from "../../../../components/UpdateTask";

interface Props {
  id: string;
  title: string;
  dateCreated: string;
  color: string;
  entries: (any | null)[];
  status: string;
  dateArchived: string | null;
  viewer: any;
}

export const Task = ({
  id,
  title,
  color,
  dateCreated,
  entries,
  status,
  dateArchived,
  viewer,
}: Props) => {
  let history = useNavigate();

  const [deleteTask, { loading: deleteTaskLoading }] = useMutation<
    deleteTasksData,
    deleteTaskVariables
  >(DELETE_TASK, {
    onCompleted: (data) => {},
    onError: () => {
      toast("😐 Oh... Reload the page and try again");
    },
    refetchQueries: [{ query: GET_TASKS }, "getTasks"],
  });

  const handleDeleteTask = async (taskId: any) => {
    await deleteTask({
      variables: {
        userId: viewer.id,
        taskId: taskId,
      },
    });
  };

  const [updateTaskModalOpen, setUpdateTaskModalOpen] = useState(false);

  const redirect = () => {
    setUpdateTaskModalOpen(true);
  };
  const [overlay, setOverlay] = useState("none");
  const [deleteTaskModalOpen, setDeleteTaskModalOpen] = useState(false);

  const [updateTask, { loading }] = useMutation<
    updateTasksData,
    updateTaskVariables
  >(UPDATE_TASK, {
    onCompleted: () => {
      setOverlay("none");
    },
    onError: () => {
      toast("😐 Oh... Reload the page and try again");
    },
  });

  const handleUpdate = async (taskId: string, input: any) => {
    await updateTask({
      variables: {
        userId: viewer.id,
        taskId: taskId,
        input: input,
      },
    });
  };

  const toggle = () => {
    if (overlay === "none") {
      setOverlay("flex");
    } else {
      setOverlay("none");
    }
  };

  const deletion = () => {
    handleDeleteTask(id);
    setDeleteTaskModalOpen(false);
  };

  const update = () => {
    handleUpdate(id, {
      status: status === "archived" ? "active" : "archived",
      dateArchived: dayjs().format("YYYY-MM-DD") + "T00:00:00.000Z",
    });
  };

  return (
    <div className="task-card">
      <UpdateTaskModal
        isOpen={updateTaskModalOpen}
        viewer={viewer}
        setOpen={setUpdateTaskModalOpen}
        taskId={id}
        title={title}
        color={color}
        setOverlay={setOverlay}
      />
      <DeleteTaskModal
        isOpen={deleteTaskModalOpen}
        deleteTask={deletion}
        setOpen={setDeleteTaskModalOpen}
        taskName={title}
      />
      {/* <DeleteTaskModal /> */}

      <div
        className="task-card-more task-card-more__more"
        onClick={() => toggle()}
      ></div>
      <div style={{}}>
        <div className="task-card__circle" style={{ backgroundColor: color }}>
          <div className="task-card__circle-text">{entries.length}</div>
        </div>
        <div className="task-card__title">{title}</div>
        <div className="task-card__numOfDays">
          {status === "active"
            ? dayjs().diff(dayjs(dateCreated), "day") + 1
            : dayjs(dateArchived).diff(dayjs(dateCreated), "day") + 1}{" "}
          days
        </div>
      </div>

      <div className="task-overlay" style={{ display: overlay }}>
        <div
          className="task-card-more task-card-more__close"
          onClick={() => toggle()}
        ></div>
        <div className="task-card__overlay-buttons" onClick={redirect}>
          Edit
        </div>
        <div className="task-card__overlay-buttons" onClick={() => update()}>
          {status === "active" ? "Archive" : "Unarchive"}
        </div>
        <div
          className="task-card__overlay-buttons task-card__overlay-buttons-delete"
          onClick={() => setDeleteTaskModalOpen(true)}
        >
          Delete
        </div>
        {loading || deleteTaskLoading ? (
          <div className="ring-overlay">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
