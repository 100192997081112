import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { NavLink } from "react-router-dom";
import { LOG_OUT } from "../../lib/graphql/mutation";
import { LogOut as LogOutData } from "../../lib/graphql/mutation/LogOut/__generated__/LogOut";
import { Viewer } from "../../lib/types";
import checklistIcon from "./assets/checklist_icon.svg";
import calendarIcon from "./assets/calendar_icon.svg";
import tasksIcon from "./assets/tasks_icon.svg";
import settingsIcon from "./assets/settings_icon.svg";
import logoutIcon from "./assets/logout_icon.svg";
import updatesIcon from "./assets/updates.svg";
import { MobileSidebar } from "./MobileSidebar";

export * from "./MobileSidebar";

interface Props {
  viewer: any;
  setViewer: (viewer: Viewer) => void;
}

export const Sidebar = ({ viewer, setViewer }: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [logOut] = useMutation<LogOutData>(LOG_OUT, {
    onCompleted: (data) => {
      if (data && data.logOut) {
        setViewer(data.logOut);
        sessionStorage.removeItem("token");
        console.log("succesfully log out");
        window.location.reload();
      }
    },
    onError: () => {
      console.log("error");
    },
  });

  const handleLogOut = () => {
    logOut();
  };

  return (
    <div>
      <MobileSidebar
        isOpen={sidebarOpen}
        setOpen={setSidebarOpen}
        logOut={logOut}
        viewer={viewer}
      />
      <div className="sidebare" style={{}}>
        <div className="sidebar-logo">QUBITS</div>
        <div className="menu-item">
          <NavLink
            to="/checklist"
            className="link"
            style={{ textDecoration: "none" }}
          >
            <div className="icon">
              <img src={checklistIcon} width={20} />
            </div>
            Checklist
          </NavLink>
        </div>
        <div className="menu-item">
          <NavLink
            to="/tasks"
            className="link"
            style={{ textDecoration: "none" }}
          >
            <div className="icon">
              <img src={tasksIcon} width={20} />
            </div>
            Tasks
          </NavLink>
        </div>
        <div className="menu-item">
          <NavLink
            to="/calendar"
            className="link"
            style={{ textDecoration: "none" }}
          >
            <div className="icon">
              <img src={calendarIcon} width={20} />
            </div>
            Calendar
          </NavLink>
        </div>
        <div className="menu-item menu-item-down">
          <NavLink
            to="/updates"
            className="link"
            style={{ textDecoration: "none" }}
          >
            <div className="icon">
              <img src={updatesIcon} width={20} />
            </div>
            Updates
          </NavLink>
        </div>
        <div
          className="menu-item"
          style={{ marginBottom: 50 }}
          onClick={() => handleLogOut()}
        >
          <div
            onClick={() => handleLogOut()}
            className="link"
            style={{ textDecoration: "none" }}
          >
            <div className="icon">
              <img src={logoutIcon} width={20} style={{ marginLeft: -5 }} />
            </div>
            Log Out
          </div>
        </div>
      </div>
    </div>
  );
};
