import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import {
  updateUser as updateUserData,
  updateUserVariables,
} from "../../lib/graphql/query/User/__generated__/updateUser";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../../lib/graphql/query/User";

import "./styles.css";
import { toast } from "react-toastify";

import rocket from "./assets/rocket.png";
import calendar from "./assets/calendar.png";
import star from "./assets/star.png";
import compass from "./assets/compass.png";
import back from "./assets/back.svg";
import forth from "./assets/forth.svg";
import starnew from "./assets/starnew.png";

const slidesData = [
  {
    title: "Welcome to Qubits!",
    description:
      "Any progress in life is possible only through consistent effort, so Qubits is designed to help you with that.",
    image: rocket,
  },
  {
    title: "Decide where impact is ...",
    description:
      "Determine what will improve your life, set regular tasks, and start completing them to make it happen.",
    image: compass,
  },
  {
    title: "... and take action.",
    description:
      "Qubits will assist you on this path, providing you with progress tracking, activity analysis, and motivation to continue.",
    image: starnew,
  },
];
const slidesDataOld = [
  {
    title: "Welcome to Qubits!",
    description:
      "Qubits designed to track consistent tasks for personal growth in any sphere of your life",
    image: rocket,
  },
  {
    title: "Decide Where Impact Is...",
    description:
      "Any progress is possible only through regular efforts. Set goals and define the regular actions that will lead you there",
    image: compass,
  },
  {
    title: "...and Take Action",
    description:
      "Qubits will assist you on this path, providing you with progress tracking, activity analysis, and motivation to continue",
    image: star,
  },
];

export const OnboardingScreen = ({ viewer, setViewer }: any) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [visibility, setVisibility] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const [updateUser] = useMutation<updateUserData, updateUserVariables>(
    UPDATE_USER
  );

  const handleUpdateUser = async () => {
    const inputhere = {
      seenIntro: true,
    };
    await updateUser({
      variables: {
        userId: viewer.id,
        input: inputhere,
      },
      onCompleted: (data) => {
        if (data.updateUser === null) {
          toast("😐 Oh... Reload the page and try again");
        }
      },
      onError: () => {
        toast("😐 Oh... Reload the page and try again");
      },
    });
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const closeOnboarding = async () => {
    setViewer({
      ...viewer,
      seenIntro: true,
    });
    console.log(viewer);
    setVisibility(false);
    await handleUpdateUser();
  };

  useEffect(() => {
    const imagePromises = slidesData.map((slide) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = slide.image;
        image.onload = resolve;
        image.onerror = reject;
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        setImagesLoaded(true);
      })
      .catch((error) => {
        console.log("Error loading images:", error);
      });
  }, []);

  const renderSlides = () => {
    const currentSlide = slidesData[currentPage];

    const slideVariants = {
      initial: { opacity: 0, y: 50 },
      animate: { opacity: 1, y: 0, transition: { delay: 0.5 } },
      exit: { opacity: 0, y: -50, transition: { duration: 0.4 } },
    };

    const paginationVariants = {
      active: { opacity: 0.8, scale: 2 },
      inactive: { opacity: 0.5, scale: 1 },
    };

    return (
      <div
        style={{
          position: "absolute",
          zIndex: 100,
          display: visibility ? "flex" : "none",
          background: "rgba(27, 28, 33, 0.95)",
          width: "100%",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* ( <AnimatePresence mode="wait">

        <motion.img
            key={currentSlide.image}
            src={currentSlide.image}
            alt={currentSlide.title}
            initial={{ y: 40, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -40, opacity: 0 }}
            transition={{
                x: { type: "spring", stiffness: 100 },
                     duration: 0.3,
                    
              }}
            width={150}
            
          />

         <motion.h2
            key={currentSlide.title}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{
                x: { type: "spring", stiffness: 100 },
                     duration: 0.3,
                    
              }}
            style={{margin:0, marginTop:5}}
            
          >
            {currentSlide.title}
       </motion.h2>

          <motion.p
            key={currentSlide.description}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{
                x: { type: "spring", stiffness: 100 },
                     duration: 0.3,
                    
              }}
            style={{maxWidth:350, textAlign:"center", fontSize:15}}
            
          >
            {currentSlide.description}
          </motion.p>

          <div style={{display:"flex", gap:15, marginTop:10}}>
          {currentPage > 0 && (
            <img src={back} onClick={handlePrev} />
          )}

          {currentPage < slidesData.length - 1 ? (
            <img src={forth} onClick={handleNext}/>
          ) : (
          <img src={forth} onClick={closeOnboarding}/>
          )}
        </div>

        </AnimatePresence>
        
        
         )}*/}

        <AnimatePresence mode="wait">
          <motion.div
            key={currentSlide.title}
            initial={{ y: 40, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -40, opacity: 0 }}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
            transition={{
              x: { type: "spring", stiffness: 100 },
              duration: 0.2,
            }}
          >
            <img
              src={currentSlide.image}
              alt={currentSlide.title}
              width={150}
              height={150}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "15px",
                marginTop: "20px",
              }}
            >
              {Array.from({ length: 3 }, (_, i) => (
                <motion.div
                  key={i}
                  style={{ marginRight: "0px", fontSize: "16px" }}
                  initial="inactive"
                  animate={currentPage === i ? "active" : "inactive"}
                  variants={paginationVariants}
                >
                  •
                </motion.div>
              ))}
            </div>

            <h2 key={currentSlide.title} style={{ margin: 0, marginTop: 25 }}>
              {currentSlide.title}
            </h2>

            <p
              key={currentSlide.description}
              style={{
                maxWidth: 360,
                textAlign: "center",
                fontSize: 16,
                fontWeight: "400",
                opacity: "70%",
                margin: 10,
              }}
            >
              {currentSlide.description}
            </p>

            <div
              style={{ display: "flex", gap: 15, marginTop: 20, height: 50 }}
            >
              {currentPage > 0 && <img src={back} onClick={handlePrev} />}

              {currentPage < slidesData.length - 1 ? (
                <img src={forth} onClick={handleNext} />
              ) : (
                <img src={forth} onClick={closeOnboarding} />
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    );
  };

  return <div>{renderSlides()}</div>;
};
