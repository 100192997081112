import face from "./assets/face.png";
import instagram from "./assets/instagram.svg";
import threads from "./assets/threads.svg";
import "./styles.css";

import post_1 from "./assets/post_1.png";

export const Updates = () => {
  return (
    <div
      className="content"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ height: 120, width: 120, marginTop: 40 }}>
        <img src={face} style={{ height: 120, width: 120 }} />
      </div>
      <div style={{ fontSize: 24, marginTop: 20 }}>Hi, i'm Arseniy👋</div>
      <div
        style={{
          fontSize: 16,
          marginTop: 10,
          textAlign: "center",
          color: "#9CA1B1",
        }}
      >
        Qubits is designed, coded, packed & delivered by me.
      </div>
      <div style={{ display: "flex", gap: 20, marginTop: 25, height: 30 }}>
        <a
          href="https://instagram.com/a_matviychuk"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} className="button-social" alt="instagram link" />
        </a>
        <a
          href="https://threads.net/a_matviychuk"
          target="_blank"
          rel="noreferrer"
        >
          <img src={threads} className="button-social" alt="threads link" />
        </a>
      </div>

      <div
        style={{
          height: 1,
          width: 250,
          background: "#5A6072",
          marginTop: 25,
          textAlign: "center",
        }}
      ></div>
      <div style={{ marginTop: 40, color: "#5A6072", textAlign: "center" }}>
        <div>Here’s the place for news and updates</div>
      </div>

      <div className="cards" style={{ lineHeight: "170%" }}>
        {/* <div className="card">
          <div style={{ fontSize: 14, opacity: 0.4 }}>29.07.2023</div>
          <div style={{ fontSize: 18, marginTop: 5 }}>
            "Updates", Blank State and more | Update #1
          </div>
          <div style={{ fontSize: 14, marginTop: 10, opacity: 0.6 }}>
            Thank you for being a part of Qubits community, and i hope it’s
            helps to grow and ... Here i'm going to cover what's i'm going to do
            in the upcoming months.
          </div>
          <div style={{ fontSize: 14, marginTop: 10, opacity: 0.6 }}>
            Thank you for being a part of Qubits community, and i hope it’s
            helps to grow and{" "}
          </div>
      </div> */}
        <div className="card">
          <div style={{ fontSize: 14, opacity: 0.4 }}>6.08.2023</div>
          <div style={{ fontSize: 18, marginTop: 5 }}>
            "Updates", Blank State and more | #1
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 10,
              opacity: 0.6,
              fontWeight: 500,
            }}
          >
            Hi! Thank you for being a part of Qubits.
            <div>
              From now on, I'll be writing about project updates on this page.
            </div>
            <p>
              Lately, I've been working hard to improve Qubits user experience:
              new "Create Task" modal, "Delete Task" modal and loading
              animations.
            </p>
            <p>
              I've also added a Blank State on all pages. Now, when you don't
              have any tasks created, "Qubits" looks much friendlier :)
            </p>
          </div>
          <div
            className="card-image"
            style={{
              marginTop: 15,
              marginBottom: 20,
            }}
          >
            <img src={post_1} style={{ maxWidth: "100%", height: "auto" }} />
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 10,
              opacity: 0.6,
              fontWeight: 500,
            }}
          >
            Testing is quite a complex task because there are numerous browsers,
            devices, and operating systems. If you spot a bug, please write to
            this email: matviychukarseniy@gmail.com. Also I'd be glad to hear
            your feedback and suggestions. <p>Stay tuned!</p>
          </div>
        </div>
      </div>
    </div>
  );
};
