import React, { useEffect, useState } from "react";
import { Task } from "./components";
import { useLazyQuery } from "@apollo/client";
import {
  getTasks as getTasksData,
  getTasksVariables,
} from "../../lib/graphql/query/Task/__generated__/getTasks";

import { GET_TASKS } from "../../lib/graphql/query/Task";

import bookmark from "../../assets/blank_state/bookmark.png";

import "../../../src/styles/index.css";
import { toast } from "react-toastify";

import { CreateTaskModalImp } from "../../components/CreateTask";

export const Tasks = ({ viewer, setViewer }: any) => {
  const [submitModalOpen, setSubmitModalOpen] = useState(false);

  const [getTasks, { data }] = useLazyQuery<getTasksData, getTasksVariables>(
    GET_TASKS,
    {
      variables: { userId: viewer.id },
      onError: () => {
        toast("😐 Oh... Reload the page and try again");
      },
    }
  );

  useEffect(() => {
    getTasks();
  }, []);

  const list = data ? data.getTasks : null;
  return (
    <div>
      <CreateTaskModalImp
        isOpen={submitModalOpen}
        viewer={viewer}
        setOpen={setSubmitModalOpen}
      />
      <div className="content" style={{ fontSize: 24, marginBottom: 20 }}>
        <div className="total-panel" style={{ fontSize: 24, marginBottom: 20 }}>
          <span style={{}}>Tasks</span>

          <div className="add-task-button">
            <button onClick={() => setSubmitModalOpen(true)}>
              + Create task
            </button>
          </div>
        </div>
        {data?.getTasks && list && list.length === 0 ? (
          <div className="checklist-blank">
            <img src={bookmark} width={150} height={150}></img>
            <div className="checklist-blank-text">
              Your tasks will be displayed here, once you create some.
            </div>

            <button
              onClick={() => setSubmitModalOpen(true)}
              className="add-task-button checklist-blank-button"
            >
              + Create task
            </button>
          </div>
        ) : (
          <div
            style={{
              fontSize: 14,
              opacity: 0.5,
              fontWeight: 1000,
              marginTop: 5,
            }}
          >
            ACTIVE
          </div>
        )}

        <div className="tasks-container">
          {list
            ? list
                .filter((task) => task!.status === "active")
                .map((d) => (
                  <Task
                    key={d!.id}
                    id={d!.id}
                    title={d!.title}
                    color={d!.color}
                    entries={d!.entries}
                    status={d!.status}
                    dateCreated={d!.dateCreated}
                    dateArchived={d!.dateArchived}
                    viewer={viewer}
                  />
                ))
            : null}
        </div>
        <div
          style={{
            fontSize: 14,
            opacity: 0.5,
            fontWeight: 1000,
            marginTop: 20,
          }}
        >
          {list && list.filter((task) => task!.status === "archived").length > 0
            ? "ARCHIVED"
            : ""}
        </div>
        <div className="tasks-container">
          {list
            ? list
                .filter((task) => task!.status === "archived")
                .map((d) => (
                  <Task
                    key={d!.id}
                    id={d!.id}
                    title={d!.title}
                    color={d!.color}
                    entries={d!.entries}
                    status={d!.status}
                    dateCreated={d!.dateCreated}
                    dateArchived={d!.dateArchived}
                    viewer={viewer}
                  />
                ))
            : null}
        </div>
      </div>
    </div>
  );
};
