import React, { useEffect, useRef } from "react";
import { Navigate } from "react-router";
import { Viewer } from "../../lib/types";
import { useApolloClient, useMutation } from "@apollo/client";
import { LOG_IN } from "../../lib/graphql";
import { AUTH_URL } from "../../lib/graphql/";
import {
  LogIn as LogInData,
  LogInVariables,
} from "../../lib/graphql/mutation/LogIn/__generated__/LogIn";
import { AuthUrl as AuthUrlData } from "../../lib/graphql/query/AuthUrl/__generated__/AuthUrl";


interface Props {
  viewer: Viewer;
  setViewer: (viewer: Viewer) => void;
}

export const LogIn = ({ setViewer, viewer }: Props) => {
  const client = useApolloClient();

  const [logIn, { data: logIndata, loading: logInLoading, error: logInError }] =
    useMutation<LogInData, LogInVariables>(LOG_IN, {
      onCompleted: (data) => {
        console.log(data);
        if (data && data.logIn && data.logIn.token && data.logIn.id !== null) {
          setViewer(data.logIn);
          console.log(viewer.loggedIn);
          sessionStorage.setItem("token", data.logIn.token);
          console.log("Big success! Log in completed");
        }
      },
    });
  const logInRef = useRef(logIn);

  useEffect(() => {
    console.log("login query runs");
    const code = new URL(window.location.href).searchParams.get("code");
    console.log("code is - " + code);

    if (code) {
      logInRef.current({
        variables: {
          input: { code },
        },
      });
    }
  }, [logInRef]);
  const handleAuthorize = async () => {
    try {
      const { data } = await client.query<AuthUrlData>({
        query: AUTH_URL,
      });
      window.location.href = data.authUrl;
    } catch {
      console.log("So sad! Please try again later");
    }
  };

  if (viewer.loggedIn === true && viewer.loggedIn !== null && sessionStorage.getItem("token") !== null) {
    return <Navigate to={`/checklist`} />;
  }

  if (logIndata && logIndata.logIn) {
    const { id: viewerId } = logIndata.logIn;
    console.log(viewer.loggedIn);
  }

  return (
    <div className="log-in">
      <div className="log-in-form">
      {navigator.userAgent.indexOf("Tiktok")===-1 &&
          navigator.userAgent.indexOf("Instagram")===-1 &&
          navigator.userAgent.indexOf("Facebook")===-1 &&
          navigator.userAgent.indexOf("Instagram")===-1 &&
          navigator.userAgent.indexOf("ByteLy")===-1 &&
          navigator.userAgent.indexOf("BytedanceWebview")===-1 &&
          navigator.userAgent.indexOf("Reddit")===-1
          ?

        <><div style={{ marginTop: "20px" }}>
          <h1>Log In</h1>
        </div>
        <div
          className="log-in-text"
          style={{
            opacity: 0.6,
            maxWidth: "80%",
            textAlign: "center",
            fontSize: "16px",
          }}
        >
          If it's your first time here, new account will be created{" "}
        </div>
        <div style={{ marginTop: "50px", marginBottom: "auto" }}>
           <button className="log-in-button" onClick={handleAuthorize}>
            Log in with Google
          </button> </div></>: (<div style={{textAlign:"center", padding:"10 0"}}> Sorry, login with in-app browsers is not supported 😢. Use Safari or Chrome instead. </div>) }

         
       
      </div>
      {/* <div
        className="log-in-text__note"
        style={{
          opacity: 0.6,
          textAlign: "center",

          fontSize: "14px",
        }}
      >
        Google do NOT share your password with Qubits. <br />
        <a
          href="https://google.com"
          target="_blank"
          rel="noreferrer"
          className="text-underline"
          style={{
            cursor: "pointer",
            color: "white",
          }}
        >
          Learn more
        </a>
      </div>
        */}
      <div className="footer"></div>
    </div>
  );
};
