import React, { useState, useEffect, useRef } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  useMutation,
  from,
} from "@apollo/client";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";

import {
  LogIn,
  PrivateRoute,
  Calendar,
  Tasks,
  Task,
  Checklist,
  NotFound,
  Updates,
} from "./sections";
import { setContext } from "@apollo/client/link/context";
import { LOG_IN } from "./lib/graphql/mutation";
import {
  LogIn as LogInData,
  LogInVariables,
} from "./lib/graphql/mutation/LogIn/__generated__/LogIn";

import { Viewer } from "./lib/types";
import "./styles/index.css";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

/*document.title = "Qubits";


 */

const link = createHttpLink({
  uri: process.env.REACT_APP_FETCH_LINK,
  credentials: "include",
});

const httpLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem("token");
  return {
    headers: {
      ...headers,
      "X-CSRF-TOKEN": token || "",
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink.concat(link),
});

const initialViewer: Viewer = {
  id: null,
  token: null,
  avatar: null,
  hasWallet: null,
  loggedIn: null,
};

const App = () => {
  const [viewer, setViewer] = useState<Viewer>(initialViewer);

  const [logIn, { data, loading, error }] = useMutation<
    LogInData,
    LogInVariables
  >(LOG_IN, {
    onCompleted: (data) => {
      if (data && data.logIn && data.logIn.id !== null) {
        setViewer(data.logIn);
      }

      if (data.logIn.token !== null) {
        sessionStorage.setItem("token", data.logIn.token);
      } else {
        /*sessionStorage.removeItem("token");*/
      }

      /* if (sessionStorage.getItem("token")===null)
      {
        navigate("/login"); 
      }*/
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const logInRef = useRef(logIn);

  useEffect(() => {
    const code = new URL(window.location.href).searchParams.get("code");
    if (
      location.pathname !== "/login" ||
      (location.pathname === "/login" && code === null)
    ) {
      logInRef.current();
    }
  }, []);

  if (error) {
    navigate("/login");
  }

  if (
    data &&
    viewer.loggedIn === null &&
    !data.logIn.token &&
    location.pathname !== "/login"
  ) {
    navigate("/login");
  }
  return (
    <div id="app">
      {loading ? (
        <div className="ring-overlay">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
      <Routes>
        <Route
          path="/login"
          element={<LogIn setViewer={setViewer} viewer={viewer} />}
        />
        <Route
          path="/checklist/*"
          element={
            <PrivateRoute
              setViewer={setViewer}
              viewer={viewer}
              Component={Checklist}
            />
          }
        />
        <Route
          path="/calendar"
          element={
            <PrivateRoute
              setViewer={setViewer}
              viewer={viewer}
              Component={Calendar}
            />
          }
        />
        {/*} <Route
            path="/payment"
            element={
              <PrivateRoute
                setViewer={setViewer}
                viewer={viewer}
                Component={Payment}
              />
            }
          />
           <Route
            path="/subscription"
            element={
              <PrivateRoute
                setViewer={setViewer}
                viewer={viewer}
                Component={Subscription}
              />
            }
          />*/}
        <Route
          path="/tasks"
          element={
            <PrivateRoute
              setViewer={setViewer}
              viewer={viewer}
              Component={Tasks}
            />
          }
        />
        <Route
          path="/task/:id/*"
          element={
            <PrivateRoute
              setViewer={setViewer}
              viewer={viewer}
              Component={Task}
            />
          }
        />
        <Route
          path="/updates"
          element={
            <PrivateRoute
              setViewer={setViewer}
              viewer={viewer}
              Component={Updates}
            />
          }
        />
        <Route
          path="*"
          element={<LogIn viewer={viewer} setViewer={setViewer} />}
        />
      </Routes>
    </div>
  );
};
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ApolloProvider client={client}>
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        className={"toast"}
        theme="dark"
        toastStyle={{ backgroundColor: "#272830" }}
      />
      <App />
    </Router>
  </ApolloProvider>
);
