import React, { useState, useEffect } from "react";
import { ReactComponent as LeftArrow } from "./assets/leftArrow.svg";
import arrow from "./assets/leftArrow.svg";
import { useNavigate, useLocation, Navigate } from "react-router-dom";

import { useQuery, useMutation } from "@apollo/client";
import {
  getTask as getTaskData,
  getTaskVariables,
} from "../../lib/graphql/query/Task/__generated__/getTask";
import {
  updateTask as updateTasksData,
  updateTaskVariables,
} from "../../lib/graphql/query/Task/__generated__/updateTask";
import {
  deleteTask as deleteTasksData,
  deleteTaskVariables,
} from "../../lib/graphql/query/Task/__generated__/deleteTask";

import {
  GET_TASK,
  UPDATE_TASK,
  CREATE_TASK,
  DELETE_TASK,
} from "../../lib/graphql/query/Task";
import dayjs from "dayjs";

import { CirclePicker } from "react-color";
import { paletteColors } from "../../lib/utils/paletteColors";

import useCollapse from "react-collapsed";
import { MobileSidebar } from "../Sidebar";

import { toast } from "react-toastify";

export const Task = ({ viewer, setViewer }: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  let history = useNavigate();

  const back = () => {
    history("/tasks");
  };
  const [titleInput, setTitleInput] = useState<string | undefined>();
  const [updateTask] = useMutation<updateTasksData, updateTaskVariables>(
    UPDATE_TASK,
    {
      onError: () => {
        toast("😐 Oh... Reload the page and try again");
      },
    }
  );
  const [deleteTask, { client }] = useMutation<
    deleteTasksData,
    deleteTaskVariables
  >(DELETE_TASK, {
    onCompleted: () => {
      history("/tasks");
    },
    onError: () => {
      toast("😐 Oh... Reload the page and try again");
    },
    update() {
      client.clearStore();
    },
  });

  const handleUpdateTask = async (taskId: string, input: any) => {
    await updateTask({
      variables: {
        userId: viewer.id,
        taskId: taskId,
        input: input,
      },
    });
  };

  const handleDeleteTask = async (taskId: any) => {
    await deleteTask({
      variables: {
        userId: viewer.id,
        taskId: taskId,
      },
    });
  };

  let textDate;
  let location = useLocation();
  textDate = location.pathname.split("/");

  const { data, loading, error }: any = useQuery<getTaskData, getTaskVariables>(
    GET_TASK,
    {
      variables: { userId: viewer.id, taskId: textDate[2] },
      onCompleted: () => setTitleInput(data.getTask.title),
      onError: () => {
        toast("😐 Oh... Reload the page and try again");
      },
    }
  );
  useEffect(() => {
    if (data) {
      setTitleInput(data.getTask.title);
    }
  }, [data]);

  const [hex, setHex] = useState("#FFCF2D");

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 1000,
  });

  const task = data ? data.getTask : null;

  const renderData = task ? (
    <div>
      {
        //        <div className="sidebar" style={{ background: sidebarr }}>
        //<img src={sidebarr} width="260" />
        // </div>
      }
      <div className="content" style={{ fontSize: 24, marginBottom: 20 }}>
        <div className="total-panel" style={{ fontSize: 24, marginBottom: 20 }}>
          <div className="left-arrow" style={{ marginTop: 5 }}>
            <LeftArrow onClick={back} />
          </div>

          <div className="add-task-button">
            <button>+ Create task</button>
          </div>
          <MobileSidebar isOpen={sidebarOpen} setOpen={setSidebarOpen} />
        </div>
        <div className="task-container">
          <div className="big-circle" style={{ background: task.color }}>
            <div className="big-circle__text">{task.entries.length}</div>
          </div>
          <div className="title-date">
            <textarea
              style={{ all: "unset", width: "100%", height: "60px" }}
              className="task-title"
              value={titleInput}
              onChange={(e) => setTitleInput(e.target.value)}
              onBlur={() => {
                handleUpdateTask(task.id, { title: titleInput });
              }}
            ></textarea>
            {/*<input
              type="textarea"
              style={{ all: "unset" }}
              className="task-title"
              value={titleInput}
              onChange={(e) => setTitleInput(e.target.value)}
              onBlur={() => {
                handleUpdateTask(task.id, { title: titleInput });
              }}
            /> */}
            <div className="task-date">
              Created {dayjs(task.dateCreated).format("DD.MM.YYYY")}
            </div>
          </div>
        </div>
        <div className="task-functional-buttons-container">
          <div className="task-color-changer" {...getToggleProps()}>
            <div
              className="task-color-changer__circle"
              style={{ background: task.color }}
            ></div>
            <div className="task-color-changer__button">
              <img
                src={arrow}
                width="10"
                style={{
                  transform: isExpanded === true ? "rotate(180deg)" : "",
                }}
              />
            </div>
          </div>
          <div {...getCollapseProps()}>
            <div style={{ padding: 10 }}>
              <CirclePicker
                colors={paletteColors}
                width={"170px"}
                className={"dropdown-expanded"}
                color={hex}
                onChange={(color) => {
                  let input = { color: color.hex };
                  handleUpdateTask(task.id, input);
                  setHex(color.hex);
                }}
              />
            </div>
          </div>

          <div
            className="task-color-changer__functional-button"
            style={{ marginTop: 15 }}
            onClick={() => {
              handleUpdateTask(task.id, {
                status: task.status === "archived" ? "active" : "archived",
                dateArchived: dayjs().format("YYYY-MM-DD") + "T00:00:00.000Z",
              });
            }}
          >
            {task.status === "archived" ? "Unarchive" : "Archive"}
          </div>

          <div
            className="task-color-changer__functional-button task-card__overlay-buttons-delete"
            onClick={() => handleDeleteTask(task.id)}
          >
            Delete
          </div>
          <div className="task-calendar-text">{/*March 2022*/}</div>
        </div>
      </div>
    </div>
  ) : null;
  return renderData;
};

/*<div
          id="calendar-task"
          className="task-calendar"
          style={{ marginTop: 20 }}
        >
          <div style={{ textAlign: "center", fontSize: 14, opacity: 0.3 }}>
            MON
          </div>
          <div style={{ textAlign: "center", fontSize: 14, opacity: 0.3 }}>
            TUE
          </div>
          <div style={{ textAlign: "center", fontSize: 14, opacity: 0.3 }}>
            WED
          </div>
          <div style={{ textAlign: "center", fontSize: 14, opacity: 0.3 }}>
            THU
          </div>
          <div style={{ textAlign: "center", fontSize: 14, opacity: 0.3 }}>
            FRI
          </div>
          <div style={{ textAlign: "center", fontSize: 14, opacity: 0.3 }}>
            SAT
          </div>
          <div style={{ textAlign: "center", fontSize: 14, opacity: 0.3 }}>
            SUN
          </div>
        </div>
        { !== undefined ? titleInput : task.title}

        <div
          id="calendar-task"
          className="task-calendar"
          style={{ marginTop: 20 }}
        >
          <div className="day-task day-blank"></div>
          <div className="day-task day-blank"></div>
          <div className="day-task day-blank"></div>
          <div className="day-task day-blank"></div>
          <div className="day-task">
            1<div></div>
          </div>
          <div className="day-task">
            2 <div className="calendar__circle" />
          </div>
          <div className="day-task">3</div>
          <div className="day-task">4</div>
          <div className="day-task">5</div>
          <div className="day-task">6</div>
          <div className="day-task">
            7 <div className="calendar__circle" />
          </div>
          <div className="day-task">8</div>
          <div className="day-task">9</div>
          <div className="day-task">
            10 <div className="calendar__circle" />
          </div>
          <div className="day-task">11</div>
          <div className="day-task">
            12 <div className="calendar__circle" />
          </div>
          <div className="day-task">13</div>
          <div className="day-task">14</div>
          <div className="day-task">15</div>
          <div className="day-task">
            16 <div className="calendar__circle" />
          </div>
          <div className="day-task">
            17 <div className="calendar__circle" />
          </div>
          <div className="day-task">18</div>
          <div className="day-task">19</div>
          <div className="day-task">
            20 <div className="calendar__circle" />
          </div>
          <div className="day-task day-not-active">21</div>
          <div className="day-task day-not-active">22</div>
          <div className="day-task day-not-active">23</div>
          <div className="day-task day-not-active">24</div>
          <div className="day-task day-not-active">25</div>
          <div className="day-task day-not-active">26</div>
          <div className="day-task day-not-active">27</div>
          <div className="day-task day-not-active">28</div>
        </div>*/
