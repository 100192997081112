import React, { useState } from "react";

import { NavLink, Navigate, useNavigate } from "react-router-dom";

import checklistIcon from "./assets/checklist_icon.svg";
import calendarIcon from "./assets/calendar_icon.svg";
import tasksIcon from "./assets/tasks_icon.svg";
import menu from "../../assets/menu.svg";
import logoutIcon from "./assets/logout_icon.svg";
import updatesIcon from "./assets/updates.svg";

import { LOG_OUT } from "../../lib/graphql/mutation";
import { LogOut as LogOutData } from "../../lib/graphql/mutation/LogOut/__generated__/LogOut";
import { Viewer } from "../../lib/types";
import { useMutation } from "@apollo/client";

import { CreateTaskModalImp } from "../../components/CreateTask";

export const MobileSidebar = ({
  viewer,
  setViewer,
  isOpen,
  setOpen,
  logOut,
}: any) => {
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const LogOutButton = () => {
    return (
      <div
        className="menu-item"
        style={{ marginBottom: 50 }}
        onClick={() => {
          logOut();
        }}
      >
        <div className="link">
          <div className="icon">
            <img src={logoutIcon} width={20} style={{ marginLeft: -5 }} />
          </div>
          Log Out
        </div>
      </div>
    );
  };

  let body = document.getElementsByTagName("body")[0];
  body.style.overflow = isOpen === true ? "hidden" : "scroll";
  return (
    <div className="mobile-sidebar-burger">
      <CreateTaskModalImp
        isOpen={submitModalOpen}
        viewer={viewer}
        setOpen={setSubmitModalOpen}
      />
      <button
        onClick={() => {
          setOpen(true);
        }}
        style={{
          background: "none",
          paddingRight: "0px",
          position: "absolute",
          top: "30px",
          right: "30px",
        }}
      >
        <img src={menu} />
      </button>

      <div
        className="mobile-sidebar-background"
        style={{ display: isOpen === true ? "block" : "none" }}
        onClick={() => {
          setOpen(false);
        }}
      ></div>
      <div
        className="mobile-sidebar sidebare"
        style={{ display: isOpen === true ? "flex" : "none" }}
      >
        <div
          className="link"
          onClick={() => {
            setSubmitModalOpen(true);
            setOpen(false);
          }}
          style={{
            marginTop: 50,
            marginBottom: 30,
            background: "#6744d5",
            borderRadius: 10,
            opacity: "80%",
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          + Create task
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            height: "2px",
            width: "190px",
            marginBottom: "10px",
            opacity: "20%",
          }}
        ></div>
        <div className="menu-item">
          <NavLink
            to="/checklist"
            className="link"
            style={{ textDecoration: "none" }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div className="icon">
              <img src={checklistIcon} width={20} />
            </div>
            Checklist
          </NavLink>
        </div>
        <div className="menu-item">
          <NavLink
            to="/tasks"
            className="link"
            style={{ textDecoration: "none" }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div className="icon">
              <img src={tasksIcon} width={20} />
            </div>
            Tasks
          </NavLink>
        </div>
        <div className="menu-item">
          <NavLink
            to="/calendar"
            className="link"
            style={{ textDecoration: "none" }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div className="icon">
              <img src={calendarIcon} width={20} />
            </div>
            Calendar
          </NavLink>
        </div>
        <div className="menu-item menu-item-down">
          <NavLink
            to="/updates"
            className="link"
            style={{ textDecoration: "none" }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div className="icon">
              <img src={updatesIcon} width={20} />
            </div>
            Updates
          </NavLink>
        </div>
        <LogOutButton />
      </div>
    </div>
  );
};
