import React from "react";

interface Props {
  count: string;
  color: string;
}

export const Medal = ({ count, color }: Props) => {
  return (
    <div className="medal" style={{ backgroundColor: color }}>
      <span>{count}</span>
    </div>
  );
};
