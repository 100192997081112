import React from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { Viewer } from "../../lib/types";
import { Sidebar } from "..";

type Props = RouteProps & {
  Component: any;
  viewer: Viewer;
  setViewer: (viewer: Viewer) => void;
};

export const PrivateRoute = ({ viewer, Component, setViewer }: Props) => {

  if (viewer){   

    if (viewer.loggedIn !== null) {

      if (Component.name ==="Payment") {
        return (<React.Fragment>
          <Component viewer={viewer} />
        </React.Fragment>)
      } 
      return ( 
      <React.Fragment>
        <Sidebar viewer={viewer} setViewer={setViewer} />
        <Component viewer={viewer} setViewer={setViewer} style={{height:"100%"}}/>
      </React.Fragment>
      )   
    
    }

   /* if (viewer && viewer.loggedIn === null) {
     return <Navigate to={`/login`} />
    }*/
  }
  
  return null
};
