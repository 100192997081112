import { gql } from "@apollo/client";

export const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      name
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userId: String!, $input: updateUserInput!) {
    updateUser(userId: $userId, input: $input) {
      id
      seenIntro
    }
  }
`;
