import React, { useState, useEffect } from "react";
import {
  updateTask as updateTasksData,
  updateTaskVariables,
} from "../../lib/graphql/query/Task/__generated__/updateTask";
import {
  getTasks as getTasksData,
  getTasksVariables,
} from "../../lib/graphql/query/Task/__generated__/getTasks";
import { useQuery, useMutation } from "@apollo/client";
import { UPDATE_TASK, GET_TASKS } from "../../lib/graphql/query/Task";

import Modal from "react-modal";
import { toast } from "react-toastify";
import { CirclePicker } from "react-color";
import { paletteColors } from "../../lib/utils/paletteColors";
import { customStyles } from "../../lib/utils/customStyles";

import dayjs from "dayjs";

export const UpdateTaskModal = ({
  isOpen,
  viewer,
  setOpen,
  taskId,
  title,
  color,
  setOverlay,
}: any) => {
  const [titleValue, setTitleValue] = useState(title);
  const [colorValue, setColorValue] = useState(color);
  const Button = () => {
    const [updateTask, { loading: loadingButton }] = useMutation<
      updateTasksData,
      updateTaskVariables
    >(UPDATE_TASK, {
      onCompleted: async (data) => {
        if (data && data.updateTask !== null && data.updateTask.id) {
          await setOpen(false);
          setOverlay("none");
        } else {
          console.log("error");
        }
      },
      onError: () => {
        toast("😐 Oh... Reload the page and try again");
      },
      refetchQueries: () => [{ query: GET_TASKS }, "getTasks"],
    });
    const handleCreate = async () => {
      if (titleValue === "") {
        toast("🤚 Wait... Let's fill that blank task name!");
      } else {
        let input = {
          title: titleValue,
          color: colorValue,
        };
        await updateTask({
          variables: {
            userId: viewer.id,
            taskId: taskId,
            input: input,
          },
        });
      }
    };
    return loadingButton ? (
      <button
        onSubmit={() => handleCreate()}
        style={{
          background: "#151518",
          opacity: 0.5,
          margin: 0,
          padding: 0,
          paddingTop: 10,
          paddingBottom: 10,
          width: "100%",
          marginTop: 20,
          fontSize: 16,
          cursor: "pointer",
        }}
      >
        Loading
      </button>
    ) : (
      <button
        type="submit"
        onClick={() => {
          handleCreate();
        }}
        style={{
          background: "#151518",
          margin: 0,
          padding: 0,
          paddingTop: 10,
          paddingBottom: 10,
          width: "100%",
          marginTop: 20,
          fontSize: 16,
          cursor: "pointer",
        }}
      >
        Update
      </button>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldReturnFocusAfterClose
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      contentLabel="Example Modal"
      style={customStyles}
      onRequestClose={() => setOpen(false)}
    >
      <span style={{ fontSize: "18px", marginBottom: 10 }}>Edit Task</span>
      {/*  <div
        className="modal-small-text"
        style={{ fontSize: "16px", marginBottom: 5 }}
      >
        Title
      </div>*/}
      <div className="input" style={{ marginTop: 15 }}>
        <input
          type="input"
          style={{ all: "unset", width: "100%", fontSize: 18 }}
          className="txt-underline"
          placeholder="Task name"
          value={titleValue}
          onChange={(e) => {
            setTitleValue(e.target.value);
          }}
        ></input>
        <span className="underline" style={{ marginTop: 5 }}></span>
      </div>
      <div className="modal-small-text" style={{ marginTop: 15 }}>
        <span style={{ fontSize: "16px", marginBottom: "0" }}>Color</span>
      </div>
      <div
        style={{
          marginTop: 5,
        }}
      >
        <CirclePicker
          colors={paletteColors}
          width={"170px"}
          className={"dropdown-expanded"}
          color={colorValue}
          onChange={(color) => {
            setColorValue(color.hex);
          }}
        />
        <Button />
        <button
          onClick={() => {
            setOpen(false);
          }}
          style={{
            background: "none",
            margin: 0,
            padding: 0,
            paddingTop: 15,
            paddingBottom: 10,
            opacity: "40%",
            width: "100%",
            fontSize: 14,
            cursor: "pointer",
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};
