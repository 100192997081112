import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import ra from "./assets/arrow-right.svg";
import la from "./assets/arrow-left.svg";
import { Medal } from "./components";

import { GET_TASKS } from "../../lib/graphql/query/Task";
import {
  getTasks as getTasksData,
  getTasksVariables,
} from "../../lib/graphql/query/Task/__generated__/getTasks";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { MobileSidebar } from "../Sidebar";

import { toast } from "react-toastify";

import { CirclePicker } from "react-color";
import { paletteColors } from "../../lib/utils/paletteColors";
import { customStyles } from "../../lib/utils/customStyles";
import Modal from "react-modal";
import {
  createTask as createTasksData,
  createTaskVariables,
} from "../../lib/graphql/query/Task/__generated__/createTask";
import { CREATE_TASK } from "../../lib/graphql/query/Task";

import { CreateTaskModalImp } from "../../components/CreateTask";

import bookmark from "../../assets/blank_state/calendar.png";

const daysOfWeek = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

document.title = "Calendar | Qubits";

export const Calendar = ({ viewer, setViewer }: any) => {
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const { data, loading, error } = useQuery<getTasksData, getTasksVariables>(
    GET_TASKS,
    {
      variables: { userId: viewer.id },
    }
  );

  const list = data ? data.getTasks : null;

  const months: any = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [fff, setF] = useState<string[]>([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const now = dayjs();
  const [date, setDate] = useState(dayjs(`${now.year()}-${now.month() + 1}-1`));

  const nextMonth = () => setDate(date.add(1, "month"));
  const previousMonth = () => setDate(date.subtract(1, "month"));

  useEffect(() => {
    createFilters();
    /* if (list) {
      let lll = [""];
      list.map((elem) => lll.push(elem!.id));
      setF(lll);
      console.log(fff);
    }*/
  }, []);

  const filtersOperatorChange = (id: string) => {
    if (fff.includes(id) !== false) {
      setF(fff.filter((v: any) => v !== id));
    }
    if (fff.indexOf(id) === -1) {
      setF((current) => [...current, id]);
    }
  };

  const CreateTaskModal = ({ onClose, isOpen }: any) => {
    const [titleValue, setTitleValue] = useState("");
    const [color, setColor] = useState(
      paletteColors[/*Math.floor(Math.random() * 8)*/ 0]
    );

    const Button = () => {
      const [createTask] = useMutation<createTasksData, createTaskVariables>(
        CREATE_TASK,
        {
          onCompleted: async (data) => {
            if (data && data.createTask === true) {
              await setSubmitModalOpen(false);
            } else {
              console.log("error");
            }
          },
          onError: () => {
            toast("😐 Oh... Reload the page and try again");
          },
          refetchQueries: () => [{ query: GET_TASKS }, "getTasks"],
        }
      );
      const handleCreate = async () => {
        let input = {
          title: titleValue,
          color: color,
          dateCreated: dayjs().format("YYYY-MM-DD") + "T00:00:00.000Z",
        };
        await createTask({
          variables: {
            userId: viewer.id,
            input: input,
          },
        });
      };
      return (
        <button
          onClick={() => {
            handleCreate();
          }}
          style={{
            background: "#151518",
            margin: 0,
            padding: 0,
            paddingTop: 10,
            paddingBottom: 10,
            width: "100%",
            marginTop: 30,
            fontSize: 16,
            cursor: "pointer",
          }}
        >
          Create
        </button>
      );
    };
    return (
      <Modal
        isOpen={isOpen}
        shouldReturnFocusAfterClose
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        contentLabel="Example Modal"
        style={customStyles}
      >
        <span style={{ fontSize: "18px", marginBottom: 10 }}>New Task</span>
        <div
          className="modal-small-text"
          style={{ fontSize: "16px", marginBottom: 5 }}
        >
          Title
        </div>
        <div className="input">
          <input
            type="input"
            style={{ all: "unset", width: "100%" }}
            className="txt-underline"
            placeholder=""
            onChange={(e) => {
              setTitleValue(e.target.value);
            }}
          ></input>
          <span className="underline"></span>
        </div>
        <div className="modal-small-text" style={{ marginTop: 15 }}>
          <span style={{ fontSize: "16px", marginBottom: "10" }}>Color</span>
        </div>
        <div
          style={{
            marginTop: 10,
          }}
        >
          <CirclePicker
            colors={paletteColors}
            width={"170px"}
            className={"dropdown-expanded"}
            color={color}
            onChange={(color) => {
              setColor(color.hex);
            }}
          />
          <Button />
          <button
            onClick={() => {
              setSubmitModalOpen(false);
            }}
            style={{
              background: "none",
              margin: 0,
              padding: 0,
              paddingTop: 15,
              paddingBottom: 10,
              opacity: "40%",
              width: "100%",
              fontSize: 14,
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    );
  };

  const Day = ({ number, titles, status, date, medals }: any) => {
    const history = useNavigate();
    return (
      <div
        className={
          status === "not-active"
            ? "day day-padding"
            : dayjs(date).isBefore(dayjs().format("YYYY-M-D")) ||
              dayjs(date).isSame(dayjs().format("YYYY-M-D"))
            ? "day"
            : "day day-not-active"
        }
        onClick={() =>
          history(`/checklist/${dayjs(date).format("YYYY-MM-DD")}`)
        }
      >
        {number}
        <div className="medal-grid">
          {medals
            ? medals.map((color: any) => {
                return (
                  <div
                    className="day-medal"
                    style={{ backgroundColor: color }}
                  />
                );
              })
            : null}
          {/* <span style={{ fontSize: 10 }}>👋</span>*/}
        </div>
      </div>
    );
  };

  const Checkbox = ({ elem }: any) => {
    const [isChecked, setIsChecked] = useState(
      fff.includes(elem.id) ? false : true
    );
    return (
      <span className="filter">
        <input
          id={elem.id}
          type="checkbox"
          className={isChecked ? "checked" : ""}
          defaultChecked={fff.includes(elem.id) ? false : true}
          style={{
            background: isChecked
              ? `${elem.color} url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.4635 7.92401L1.09912 4.56688L2.37855 3.28383L4.46169 5.36335L9.25863 0.566406L10.5399 1.84764L4.4635 7.92401Z' fill='%23151518' stroke='%23151518' stroke-width='0.5'/%3E%3C/svg%3E") no-repeat`
              : "none",
            border: `0.15em solid ${elem.color}`,
          }}
          name={elem.id}
          onClick={() => {
            setIsChecked((prev) => !prev);
            filtersOperatorChange(elem.id);
          }}
        />
        <label
          style={{ color: elem.color }}
          htmlFor={`${elem.id}`}
          onClick={() => setIsChecked((prev) => !prev)}
        >
          {elem.title}
        </label>
      </span>
    );
  };

  const Counter = ({ elem }: any) => {
    return (
      <span className="filter" style={{ fontSize: 16, marginRight: 30 }}>
        <input
          id={elem.id}
          type="checkbox"
          defaultChecked={fff.includes(elem.id) ? false : true}
          name={elem.title}
          onClick={() => filtersOperatorChange(elem.id)}
        />
        <label htmlFor={`${elem.title}`} style={{ color: elem.color }}>
          {elem.title}
        </label>
      </span>
    );
  };

  const createFilters = () => {
    const filters: any = [];
    const counters: any = [];

    if (list) {
      list.map((elem: any) => {
        for (let i = 0; i < Object.keys(elem.entries).length; i++) {
          if (
            dayjs(elem.entries[i]) < date.add(1, "month") &&
            dayjs(elem.entries[i]) >=
              dayjs(`${date.year()}-${date.month() + 1}-1`)
          ) {
            let entriesCount = 0;
            elem.entries.map((entry: any) => {
              if (
                dayjs(entry) < date.add(1, "month") &&
                dayjs(entry) >= dayjs(`${date.year()}-${date.month() + 1}-1`)
              ) {
                entriesCount = +1;
              }
              counters.push(
                <Medal count={`${entriesCount}`} color={elem.color} />
              );
            });

            return filters.push(<Checkbox elem={elem} key={elem.id} />);
          }
        }

        /* 
        
        ${elem.color}

        ${url(${CheckIcon}) no-repeat
        
        if (
          dayjs(timestamp) < date.add(1, "month") &&
          dayjs(timestamp) > date.subtract(1, "month")
        ) {
          return console.log("im here");
        } */
      });
    }
    if (filters.length >= 2) {
      /* filters.unshift(
        <span className="filter" style={{ fontSize: 16, marginRight: 30 }}>
          <input
            id={"mark-all"}
            type="checkbox"
            name={"mark-all"}
            defaultChecked={fff.length === 0 ? true : false}
          />
          <label htmlFor={"mark-all"} style={{ color: "#fff" }}>
            Mark all
          </label>
        </span>
      );*/
    }
    return filters;
  };

  const createCounters = () => {
    const counters: any = [];
    if (list) {
      list.map((elem: any) => {
        for (let i = 0; i < Object.keys(elem.entries).length; i++) {
          if (
            dayjs(elem.entries[i]) < date.add(1, "month") &&
            dayjs(elem.entries[i]) >=
              dayjs(`${date.year()}-${date.month() + 1}-1`)
          ) {
            let entriesCount = 0;
            elem.entries.map((entry: any) => {
              if (
                dayjs(entry) < date.add(1, "month") &&
                dayjs(entry) >= dayjs(`${date.year()}-${date.month() + 1}-1`)
              ) {
                entriesCount += 1;
              }
            });
            return counters.push(
              <Medal count={`${entriesCount}`} color={elem.color} />
            );
          }
        }

        /* if (
          dayjs(timestamp) < date.add(1, "month") &&
          dayjs(timestamp) > date.subtract(1, "month")
        ) {
          return console.log("im here");
        } */
      });
    }
    return counters;
  };

  const days: any = [];

  const printDays = () => {
    for (
      let i = 1;
      i < (date.startOf("month").day() === 0 ? 7 : date.startOf("month").day());
      i++
    ) {
      days.push(<Day number={i} status={"not-active"} />);
    }

    for (let i = 1; i <= date.daysInMonth(); i++) {
      let medals: any = [];

      if (list) {
        list.map((elem: any) => {
          /* if (elem.array.includes(i)) {
            medals.push(elem.color);
          }*/

          elem.entries.map((timestamp: string) => {
            if (
              dayjs(timestamp).format("YYYY-M-D") ===
              dayjs(`${date.year()}-${date.month() + 1}-${i}`).format(
                "YYYY-M-D"
              )
            ) {
              if (fff.includes(elem.id) === false) {
                medals.push(elem.color);
              }
            }
          });
        });
      }

      days.push(
        <Day
          date={`${date.year()}-${date.month() + 1}-${i}`}
          number={i}
          medals={medals}
        />
      );
    }

    return days;
  };

  /* Modal */
  const openCreate = () => {
    setSubmitModalOpen(true);
  };

  /* for (let i = 1; i < paddingdays; i++) {
    return <Day></Day>;
  }*/

  return (
    <div>
      <CreateTaskModalImp
        isOpen={submitModalOpen}
        viewer={viewer}
        setOpen={setSubmitModalOpen}
      />
      {
        //        <div className="sidebar" style={{ background: sidebarr }}>
        //<img src={sidebarr} width="260" />
        // </div>
      }
      <div className="content" style={{ fontSize: 24, marginBottom: 20 }}>
        <div className="total-panel" style={{ fontSize: 24, marginBottom: 20 }}>
          <span style={{}}>Calendar</span>

          <div className="add-task-button">
            <button onClick={() => setSubmitModalOpen(true)}>
              + Create task
            </button>
          </div>
          {/*} <MobileSidebar
            isOpen={sidebarOpen}
            setOpen={setSidebarOpen}
            setCreateTaskOpen={openCreate}
    />*/}
        </div>

        {data?.getTasks && list && list.length === 0 ? (
          <div className="checklist-blank">
            <img src={bookmark} width={150} height={150}></img>
            <div className="checklist-blank-text">
              Your activity will appear here, ready for analysis.
            </div>

            <button
              onClick={() => setSubmitModalOpen(true)}
              className="add-task-button checklist-blank-button"
            >
              + Create task
            </button>
          </div>
        ) : (
          <React.Fragment>
            <div className="date-picker">
              <div
                className="date-picker__before"
                onClick={() => previousMonth()}
              >
                <img src={la} width="10" style={{ marginTop: 14 }} />
              </div>
              <div className="date-picker__main">
                <span className="date-picker__month">
                  {months[date.month()]} {date.year()}
                </span>
                {/*<div className="calendar-icon" style={{ height: 40 }}>
            <img src={cal} height="20" style={{ marginTop: 9 }} />
  </div>*/}
              </div>
              <div className="date-picker__after" onClick={() => nextMonth()}>
                <img src={ra} width="10" style={{ marginTop: 14 }} />
              </div>
              <div className="medals-section">{createCounters()}</div>
            </div>
            <div style={{}}>{createFilters()}</div>

            <div id="calendar" style={{ marginTop: 20 }}>
              {daysOfWeek.map((day) => {
                return (
                  <div
                    style={{ textAlign: "center", fontSize: 14, opacity: 0.3 }}
                  >
                    {day}
                  </div>
                );
              })}
            </div>
            <div id="calendar" style={{ marginTop: 20 }}>
              {printDays()}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

/*  

<div>
        <EmojiPicker onEmojiClick={() => {}} />
      </div>
*/
