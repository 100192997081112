import { gql } from "@apollo/client";

export const LOG_OUT = gql`
  mutation LogOut {
    logOut {
      id
      token
      avatar
      hasWallet
      loggedIn
    }
  }
`;
