export const customStyles = {
  content: {
    display: "flex",
    flexDirection: "column" as "column",
    border: "none",
    borderRadius: 40,
    width: 280,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    "text-align": "center",
    backgroundColor: "#1B1C22",
    padding: "30px",
    paddingBottom: "15px",
  },
  overlay: {
    backgroundColor: "rgba(19, 18, 24, 0.9)",
  },
};
