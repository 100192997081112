import { gql } from "@apollo/client";

export const GET_TASKS = gql`
  query getTasks($userId: ID!) {
    getTasks(userId: $userId) {
      id
      title
      color
      status
      dateCreated
      dateArchived
      entries
    }
  }
`;

export const GET_TASK = gql`
  query getTask($userId: ID!, $taskId: ID!) {
    getTask(userId: $userId, taskId: $taskId) {
      id
      title
      color
      status
      dateCreated
      dateArchived
      entries
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation updateTask($userId: ID!, $taskId: ID!, $input: updateTaskInput!) {
    updateTask(userId: $userId, taskId: $taskId, input: $input) {
      id
      title
      color
      status
      dateCreated
      dateArchived
      entries
    }
  }
`;

export const CREATE_TASK = gql`
  mutation createTask($userId: ID!, $input: createTaskInput!) {
    createTask(userId: $userId, input: $input)
  }
`;

export const DELETE_TASK = gql`
  mutation deleteTask($userId: ID!, $taskId: ID!) {
    deleteTask(userId: $userId, taskId: $taskId) {
      id
    }
  }
`;
