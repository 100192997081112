export const paletteColors = [
  "#FFCF2D",
  "#926BFF",
  "#0BFFB6",
  "#6a964e",
  "#05E1FF",
  "#FF6D1B",
  "#B2FF35",
  "#FF3838",
];
