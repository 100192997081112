import React, { useState, useEffect } from "react";

import {
  deleteTask as deleteTasksData,
  deleteTaskVariables,
} from "../../lib/graphql/query/Task/__generated__/deleteTask";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_TASK } from "../../lib/graphql/query/Task";

import Modal from "react-modal";
import { customStyles } from "../../lib/utils/customStyles";
import { toast } from "react-toastify";

export const DeleteTaskModal = ({
  taskName,
  isOpen,
  setOpen,
  deleteTask,
}: any) => {
  const DeleteButton = () => {
    /* const [deleteTask] = useMutation<deleteTasksData, deleteTaskVariables>(
      DELETE_TASK,
      {
        onCompleted: (data) => {},
        onError: () => {
          toast("😐 Oh... Reload the page and try again");
        },
        /*refetchQueries: [{ query: GET_TASKS }, "getTasks"],
      }
    );
    const handleDeleteTask = async () => {
      await deleteTask({
        variables: {
          userId: viewer.id,
          taskId: taskId,
        },
      });
    };*/
    const handleDeleteTask = () => {
      deleteTask();
    };
    return (
      <button
        onClick={() => handleDeleteTask()}
        style={{
          background: "#8F1E20",
          margin: 0,
          padding: 0,
          paddingTop: 10,
          paddingBottom: 10,
          width: "100%",
          fontSize: 16,
          cursor: "pointer",
        }}
      >
        Delete
      </button>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldReturnFocusAfterClose
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      contentLabel="Example Modal"
      style={customStyles}
      onRequestClose={() => {
        setOpen(false);
      }}
    >
      <span style={{ fontSize: "18px", marginBottom: 10 }}>Task Deletion</span>
      <div
        className="modal-small-text"
        style={{ fontSize: "16px", marginBottom: 5 }}
      >
        Are you sure you want to delete the task{" "}
        <span style={{ color: "#fff" }}>{taskName}</span>?
        <p>
          This action will permanently remove all related entries. To keep them,
          archive the task instead of deleting.
        </p>
      </div>
      <div></div>
      <div>
        <DeleteButton />
        <button
          onClick={() => {
            setOpen(false);
          }}
          style={{
            background: "none",
            margin: 0,
            padding: 0,
            paddingTop: 15,
            paddingBottom: 10,
            opacity: "40%",
            width: "100%",
            fontSize: 14,
            cursor: "pointer",
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};
