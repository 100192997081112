import React, { useState, useEffect } from "react";
import { ReactComponent as CheckIcon } from "../../../../assets/Vector.svg";

import { useMutation } from "@apollo/client";

import {
  updateTask as updateTasksData,
  updateTaskVariables,
} from "../../../../lib/graphql/query/Task/__generated__/updateTask";
import {
  GET_TASKS,
  UPDATE_TASK,
  CREATE_TASK,
  DELETE_TASK,
} from "../../../../lib/graphql/query/Task";

import { toast } from "react-toastify";

interface Props {
  id: string;
  title: string;
  color: string;
  status: any;
  entries: (any | null)[];
  date: any;
  updateTask: any;
  viewer: any;
}

export const Task = ({
  id,
  title,
  color,
  status,
  entries,
  date,
  updateTask,
  viewer,
}: Props) => {
  useEffect(() => {
    setstat(
      entries.includes(date.format("YYYY-MM-DD") + "T00:00:00.000Z")
        ? "done"
        : "none"
    );
  }, [date]);

  //const extractDate = async (entries: [string]) => {
  // entries.map(async (ISODate) => {
  //   if (date.format("YYYY-MM-DD") === dayjs(ISODate).format("YYYY-MM-DD")) {
  //     console.log("!!!!!");
  //     await setstat("done");
  ///   }
  //  console.log(

  //    date.format("YYYY-MM-DD") + "////" + dayjs(ISODate).format("YYYY-MM-DD")
  //   );
  //  });
  // await setstat("none");
  // };

  const [stat, setstat] = useState(
    entries.includes(date.format("YYYY-MM-DD") + "T00:00:00.000Z")
      ? "done"
      : "none"
  );

  const [update, { loading }] = useMutation<
    updateTasksData,
    updateTaskVariables
  >(UPDATE_TASK);

  const handleUpdateTask = async (taskId: string, input: any) => {
    await update({
      variables: {
        userId: viewer.id,
        taskId: taskId,
        input: input,
      },
      onCompleted: (data: any) => {
        if (data.updateTask === null) {
          toast("😐 Oh... Reload the page and try again");
        }
      },
      onError: () => {
        toast("😐 Oh... Reload the page and try again");
      },
    });
  };

  const toggle = async (id: string, status: any) => {
    if (stat === "done") {
      await setstat("none");
      let newarr = entries
        .slice()
        .filter(
          (entry) => entry !== date.format("YYYY-MM-DD") + "T00:00:00.000Z"
        );
      /* newarr.filter(
        (entry) => entry !== `${dayjs(date.format("DD/MM/YYYY")).toISOString()}`
      );*/

      const updatedItem = {
        entries: newarr,
      };
      handleUpdateTask(id, updatedItem);
    } else {
      await setstat("done");

      let newarr = entries.slice();
      newarr.push(date.format("YYYY-MM-DD") + "T00:00:00.000Z");
      console.log(date.format("YYYY-MM-DD") + "T00:00:00.000Z");
      const updatedItem = {
        entries: newarr,
      };

      handleUpdateTask(id, updatedItem);
      console.log(updatedItem);
    }
  };

  return (
    <div className="task">
      <div className="project-container">
        {loading ? (
          <div className="ring-overlay-small">
            <div className="lds-ring-small">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : null}
        <div
          className={"icon-color"}
          style={
            stat === "done"
              ? { color: color, borderColor: color, backgroundColor: color }
              : {
                  color: color,
                  opacity: 0.7,
                  borderColor: color,
                }
          }
          onClick={() => toggle(id, stat)}
        >
          <CheckIcon />
        </div>

        <div
          style={
            stat === "done"
              ? { opacity: 1 }
              : {
                  opacity: 0.5,
                }
          }
        >
          {" "}
          <span style={{}}>{title}</span>
        </div>
      </div>
    </div>
  );
};
